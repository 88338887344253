var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-btn',{attrs:{"id":"btn1","color":"primary","dark":""},on:{"click":function($event){return _vm.dialogModal(true, 'create')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$('create'))+" ")],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.data,"options":_vm.options,"loading":_vm.loading,"footer-props":{
      itemsPerPageOptions:[10, 20, 50, -1],
      itemsPerPageText: _vm.$t('dataTable.itemsPerPageText'),
      pageText: _vm.$t('dataTable.pageText'),
      itemsPerPageAllText: _vm.$t('dataTable.itemsPerPageAllText'),
    }},on:{"update:options":function($event){_vm.options=$event},"update:page":_vm.fetch,"update:items-per-page":_vm.fetch},scopedSlots:_vm._u([{key:"item.expire",fn:function(ref){
    var item = ref.item;
return [(_vm.diffDays(new Date(item.expire), new Date()) > 0)?_c('v-chip',{attrs:{"small":"","color":"success"}},[_vm._v(" "+_vm._s(_vm.diffDays(new Date(item.expire)))+" يوم ")]):_c('v-chip',{attrs:{"small":"","color":"error"}},[_vm._v(" منتهي الصلاحية ")])]}},{key:"item.banner",fn:function(ref){
    var item = ref.item;
return [_c('v-img',{attrs:{"src":item.banner,"width":"200","height":"80"}})]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text-center",attrs:{"id":"actions"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"dark"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[_c('menu-item',{attrs:{"label":_vm.$('update'),"color":"info","icon":"mdi-pencil"},on:{"click":function($event){return _vm.dialogModal(true, 'update', item)}}}),_c('menu-item',{attrs:{"label":_vm.$('delete'),"color":"error","icon":"mdi-delete"},on:{"click":function($event){return _vm.dialogModal(true, 'delete', item)}}})],1)],1)],1)]}}])}),_c('user-dialog',{attrs:{"dialog-modal":_vm.dialogModal,"data":_vm.dialogData,"translation-key":_vm.translationKey},on:{"update:table":_vm.fetch}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }